* {
  box-sizing: border-box;
}

body {
  font-family: 'Tajawal', sans-serif;
  margin: 0;
  padding: 0;
  direction: rtl;
  background-color:  #E2F2E2;
}

h1, h2, h3, p, ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: black;
}

/* === App === */

.main {
  display: flex;
  flex-direction: row;
}

.right-main {
  width: 75%;
}

.left-main {
  width: 400px;
}

.left-container {
  width: 82%;
}

/* === Header === */

header {
  background-color: #5E8C63;
  color: white;
  padding: 0.5em 5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

header h1, .title a {
  font-size: 30px;
  color: white;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-img {
  width: 70px;
  height: 70px;
}
 
#search-title-div {
  width: 400px;
  height: 150px;
  border-radius: 12px;
  background-color: #32453d;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  margin-top: 6em;
  top: 0;
  z-index: 1;
}

.search-submit {
  margin-bottom: 1.5em;
  background-color: #5E8C63;
  color: white;
  border: none;
  border-radius: 12px;
  font-family: inherit;
  padding: 0.4em 1.5em;
  font-size: 16px;
}

.search-submit:hover {
  opacity: 0.7;
}

.search-title {
  font-family: inherit;
  margin-top: 1.5em;
  width: 300px;
  padding: 1em 5em;
  border-radius: 12px;
  /* border: 2px solid #374B43; */
  border: none;
}

.search-icon {
  margin-right: 2em;
  height: 40px;
  width: 40px;
}

.search-title:hover, .search-title:focus, .search-title:active {
  border: none;
}

.search-icon:hover, .search-icon:focus, .search-submit:hover, .search-submit:focus {
  cursor: pointer;
}

nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}

nav .nav-li {
  margin-left: 2em;
}

nav a {
  color: white;
  font-size: 23px;
  font-weight: 500;
}

/* === Update === */
.update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(12, 115, 12)
}

.update span {
  color: black;
}

.box {
  margin: 0 auto;
  padding: 0.5em;
  background-color: white;
  margin-bottom: 1em;
  border-bottom: 1px solid black;
}

.update-text {
  display: flex;
  flex-direction: row;
}

marquee p
{
  
    white-space: nowrap;
    margin-left: 3em;
}


/* === Table === */

.main-table {
  margin-right: 5em;
  margin-bottom: 2em;
}

.main-table h2 {
  margin-bottom: 1em;
}

table {
  width: 90%;
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
}

th, td {
  padding: 5px 0;
}

th {
 background-color: #5E8C63;
}

tr:nth-child(even) {
  background-color: #c0d4c0;
}

table a:hover, table a:focus {
  color: #690707;
  text-decoration: underline;
}

.green {
  color: green;
}

.red {
  color: red;
}

/* === Search Bar === */
.line {
  border-bottom: 8px solid #374B43;
  margin-top: 1em;
}
.search-filter input {
  margin-top: 1em;
  width: 100%;
  padding: 6px 15px;
  border-radius: 7px;
  border: 1.5px solid #374B43;
  font-family: inherit;
}

/* === Explore tools === */

.explore-tools {
  margin: 0;
}

.explore-tools h3 {
  margin: 0.5em 0;
}

.explore-tools a {
  font-size: 18px;
  font-weight: 700;
  color: #32453d;
}

.explore-tools p {
  margin: 0.7em 0 0.5em 0;
  font-size: 15px;
}

.tool-box {
  padding: 9px 15px 1px 15px;
  margin-bottom: 0.5em;
  background-color: #c0d4c0;
}

/* === Footer === */

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5E8C63;
  color: white;
  margin-top: 10em;
  height: 70px;
}