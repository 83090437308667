
/* === Crops === */
.crops-container {
    width: 90%;
    margin: 0 auto;
}

.main-crops {
    margin: 3em 0;
    display: flex;
    flex-direction: row;
    height: 350px;
    justify-content: space-between;
    align-items: center;
}

.crops {
    
}


.crops h1 {
    font-size: 26px;
    padding-bottom: 0.5em;
}

.small {
    font-size: 17px;
    color: #32453d;
    font-weight: 300;
}

/* === Top Info === */
.top-info {
    padding-bottom: 2em;
    background-color: #c0d4c0;
    padding: 1em;
    padding-left: 3em;
    border-radius: 5px;
    align-self: stretch;
}

.top-info-title {
    margin-bottom: 1em;
}

.top-info h1 {
   
    font-size: 45px;
}

.top-info p {
    font-size: 18px;
}

.chart-c {
    height: 100%;
    width: 70%;
    border: 1px solid #c0d4c0;
    border-radius: 5px;
    padding-top: 1em;

}

/* === Crop Bars === */
.crop-bars {
    display: flex;
    justify-content: flex-start;
}

.crop-bars ul {
    border-bottom: 2px solid #32453d;
    padding-bottom: 0.7em;
    width: 100%;
    justify-content: flex-start;
}

#gr {
    color: black;
}

.crop-bars a {
    color: #1e6c4b;
    font-weight: 400;
    font-size: 21px;
    margin-left: 1em;
}

/* === Time Rates === */
.rates {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1.5px solid #32453d;
    border-radius: 12px;
    padding: 5px 5px;
    justify-content: space-between;
    
}

.rate-div {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    padding: 12px 40px;
    border-radius: 12px;
    background-color: transparent;
}

.rate-div:hover, .rate-div:focus {
    padding: 12px 40px;
    border-radius: 12px;
    background-color: #85ddb8;
}

.crop-data {
    width: 100%;
    margin-top: 1em;
    margin-right: 0;
}


/* === Crops detail === */
.crops-detail-content {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}
.crop-box {
    background-color: #c0d4c0;
    padding: 1em 2em;
    height: 100%;
    height: 100%;
    justify-self: flex-start;
    align-self: start;
    border-radius: 8px;
}

.crop-box h2 {
    margin-bottom: 0.5em;
}

.crops-states {
    display: flex;
    flex-direction: column;
    
}

.chart-pie {
    font-family: inherit;

}


/* === Crops Detail Rows === */ 
.crops-detail-rows {
    display: grid;
    gap: 1em;
    margin-bottom: 1em;
}

.row-one {
    grid-template-columns: 30% auto auto;
    grid-template-rows: auto;
}

.row-two {
    grid-template-columns: auto 50% auto ;
}

.crops-brief {
    min-height: 200px;
}

.crops-prices {
    min-height: 300px ;
    width: 100%;
}

.chart-area {
    height: 100%;
    width: 100%;
}

.crops-area-prices {
    height: 250px;
}

.crops-area-prices h2 {
    margin-bottom: 0.5em;
}